<template>
  <div class="page">
    <Navbar title="微客申请" type="SMP" />
    <van-form @submit="createApply" class="form">
      <van-cell-group>
        <van-field name="radio" label="商户类型">
          <template #input>
            <van-radio-group
              v-model="form.type"
              :checked-color="COLOR"
              direction="horizontal"
            >
              <van-radio name="PSN">个人</van-radio>
              <van-radio name="ETP">公司</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="form.name"
          name="名称"
          label="名称"
          placeholder="请输入个人姓名或企业名称"
          :rules="[{ required: true, message: '请输入个人姓名或企业名称' }]"
        />
        <van-field
          v-model="form.identify"
          name="识别号码"
          label="识别号码"
          placeholder="请输入身份证号或营业执照号"
          :rules="[{ required: true, message: '请输入身份证号或营业执照号' }]"
        />
        <van-field
          v-model="form.operatorName"
          is-link
          readonly
          name="picker"
          label="运营商"
          placeholder="点击选择运营商"
          @click="operatorShow = true"
          :rules="[{ required: true, message: '请选择关联的运营商' }]"
        />
        <van-popup :show="operatorShow" position="bottom">
          <van-picker
            :columns="operators"
            :columns-field-names="{
              text: 'name',
            }"
            @confirm="operatorConfirm"
            @cancel="operatorShow = false"
          />
        </van-popup>
      </van-cell-group>
      <van-row class="tips">
        <van-col span="24"
          ><span v-if="state === 'NVF'">审核中,请耐心等待...</span>
          <span v-if="state === 'PAS'">该用户已完成注册</span></van-col
        >
      </van-row>
      <van-row>
        <van-col offset="6" span="12" class="subbtn">
          <van-button
            block
            size="small"
            color="#00CC99"
            v-if="state === 'NML'"
            native-type="submit"
          >
            提交申请
          </van-button>
          <van-button
            block
            size="small"
            type="warning"
            v-if="state === 'NVF'"
            @click="updateApply"
          >
            修改申请
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import { Form, Field, CellGroup, RadioGroup, Radio, Picker, Popup, Area, Steps, Step } from 'vant'
import Navbar from '../../module/common/Navbar.vue'
import Loading from '../../module/common/Loading.vue'
import Share from '../../module/common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    Share: Share,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Area.name]: Area,
    [Steps.name]: Steps,
    [Step.name]: Step
  },
  data () {
    return {
      loadingShow: false,
      code: '',
      loginState: 'N',
      state: 'NML',
      inviterUser: '',
      inviterSeller: '',
      form: {
        type: 'PSN',
        name: '',
        operatorName: '',
        operatorCode: '',
        identify: ''
      },
      operatorShow: false,
      operators: [
        { code: '1', name: '微职客西安运营中心' },
        { code: '2', name: '微职客渭南运营中心' }
      ]
    }
  },
  mounted () {
    var query = this.$route.query
    var inviterUser = query.inviterUser
    var inviterSeller = query.inviterSeller
    if (inviterUser !== undefined && inviterUser !== null) {
      this.inviterUser = inviterUser
    }
    if (inviterSeller !== undefined && inviterSeller !== null) {
      this.inviterSeller = inviterSeller
    }
    var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
    if (query.code !== undefined && token === null) {
      this.code = query.code
      this.login()
    } else if (token === null) {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa8145a74e5f2b39a&redirect_uri=https%3A%2F%2Fmoc.utopanet.com%2Fseller%2Fapply%3FinviterUser%3D' + inviterUser + '%26inviterSeller%3D' + inviterSeller + '&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
      this.loginState = 'Y'
    } else {
      this.loginState = 'Y'
      this.retrieveApply()
    }
    this.$refs.share.default('seller')
  },
  methods: {
    async login () {
      var pd = { code: this.code, source: 'WPP' }
      var { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/loginBind', this.$qs.stringify(pd))
      if (res.status === '200') {
        window.sessionStorage.setItem(this.SESSION_TOKEN, res.data.token)
        window.sessionStorage.setItem(this.SESSION_USER, res.data.userCode)
        this.loginState = 'Y'
        window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
        this.retrieveApply()
      } else if (res.code === '4002') {
        window.sessionStorage.setItem(this.SESSION_SERIAL, res.data.openid)
        this.$router.push({ path: '/bind' })
      }
    },
    async retrieveApply () {
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/apply/retrieveApply')
      if (res.status === '200') {
        this.form.type = res.data.type
        this.form.name = res.data.name
        this.form.identify = res.data.identify
        this.form.operatorCode = res.data.operatorCode
        this.initOperator(res.data.operatorCode)
        this.state = res.data.state
        if (res.data.state === 'PAS') {
          this.$router.push({ path: '/seller/home/enroll' })
        }
      }
    },
    async createApply () {
      this.loadingShow = true
      var pd = {
        operatorCode: this.form.operatorCode,
        name: this.form.name,
        identify: this.form.identify,
        inviterUser: this.inviterUser,
        inviterSeller: this.inviterSeller,
        type: this.form.type
      }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/apply/createApply', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.autoAudit()
      }
    },
    async autoAudit () {
      var pd = { operatorCode: this.form.operatorCode, identify: this.form.identify, result: 'PAS' }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/apply/auditApply', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.loadingShow = false
        this.$router.push({ path: '/seller/home/enroll' })
      }
    },
    async updateApply () {
      this.loadingShow = true
      var pd = { agentCode: this.form.agentCode, name: this.form.name, identify: this.form.identify, type: this.form.type }
      const { data: res } = await this.$http.post(this.UMS_URL + '/market/apply/updateApply', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.loadingShow = false
        this.state = 'APR'
        this.$dialog.alert({
          title: '提示信息',
          message: '修改成功，请耐心等待审核'
        })
      }
    },
    operatorConfirm (val) {
      this.form.operatorCode = val.code
      this.form.operatorName = val.name
      this.operatorShow = false
    },
    initOperator (code) {
      var that = this
      that.operators.forEach(element => {
        if (element.code === code) {
          that.form.operatorName = element.name
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  margin-top: 0px;
  .subbtn {
  }
  .tips {
    margin: 10px auto;
    color: red;
  }
}
</style>
